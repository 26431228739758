<template>
  <div class="punctuation">
    <i-header></i-header>
    <div class="labelPage">
      <i-navigate :navigate="channel"></i-navigate>
    </div>
    <div class="picter box_flex">
      <div
        class="point mongolia"
        id="mongolia"
        :class="{ pointLeft: pointLeft }">
        <div class="cavmap"><img :src="makerImg.src" /></div>
        <!--<div
          class="marker"
          :class="item.class"
          v-for="(item, index) in makerData"
          :key="index"
          v-if="index!='canvas'"
          :style="{ left: (parseInt(item.coords.lat))/192/1.85 +'rem', top:(parseInt(item.coords.long))/192/1.85 +'rem' }"
          >
          <div class="box_flex">
            <div class="adress" @mouseenter="cutIn(item)" @mouseleave="packUp">
              <div class="adrcon">
                <div class="dot"></div>
                <div class="caption ellipsis">{{ item.content }}</div>
              </div>
              <div class="texelink"  v-show="item == seeindex"><p class="ellipsis2">{{item.txt}}</p><a :href="item.url">查看更多>></a></div>
            </div>
          </div>
        </div>-->
        <div class="marker" :class="item.class"
             v-for="(item, index) in makerData"
             :key="index"
             v-if="index!='canvas'"
             :style="{ left: (parseInt(item.coords.lat))/192 +'rem', top:(parseInt(item.coords.long) - 30)/192 +'rem' }">
          <div class="box_flex" @mouseenter="cutIn(item)" @mouseleave="packUp">
            <div class="adrcon">
              <div class="dot" @click="onModal(index)"></div>
              <div class="caption ellipsis" v-show="item.content">{{ item.content }}</div>
            </div>
            <div class="texelink"  v-show="item == seeindex"><p class="ellipsis2"  v-show="item.txt">{{item.txt}}</p><a :href="item.url" v-show="item.url">查看更多>></a></div>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <div class="tuning" :class="{ tunfade: tunfade }" v-show="tuning">
          <div
            class="tunrow"
            v-for="(tun, tunIndex) in tunrowData"
            :key="tunIndex"
          >
            <label
              :class="blushPlay == con.text ? 'blush' : ''"
              v-for="(con, conIndex) in tun.children"
              :key="conIndex"
              @click="board(con, $event)"
            >
              <span :class="con.addClass"></span>{{ con.text}}
              <audio :src="con.music" class="music"></audio>
            </label>
            <p></p>
          </div>
        </div>
      </transition>
    </div>
    <div class="erhuside box_flex" :class="{ spread: spread }">
      <div class="sideImg">
<!--        <div class="packUp box_flex">
          <div class="sideIcon" @click="unfold"></div>
          <span v-show="hidle">二胡</span>
        </div>-->
      </div>
      <div class="sideBg"  ref="content">
        <div class="sideScrol scrol" ref="detailcont">
          <h3>{{channel.name}}</h3>
          <div class="armila">
            <div class="sponsor">
              <p v-if="channel.fullName"><label>全称</label>:<span>{{ channel.fullName }}</span> </p>
              <p v-if="channel.originalName"><label>原称</label>:<span>{{ channel.originalName }}</span> </p>
              <p v-if="channel.abbreviationName"><label>简称</label>:<span>{{ channel.abbreviationName }}</span> </p>
              <p v-if="channel.commonlyName"><label>俗称</label>:<span>{{ channel.commonlyName }}</span> </p>
              <p v-if="channel.alsoName"><label>又称</label>:<span>{{ channel.alsoName }}</span> </p>
              <p v-if="channel.nickName"><label>别称</label>:<span>{{ channel.nickName }}</span> </p>
              <p v-if="channel.author"><label>作者</label>:<span>{{ channel.author }}</span> </p>
              <p v-if="channel.creator"><label>创作者</label>:<span>{{ channel.creator }}</span> </p>
              <p v-if="channel.engraver"><label>刻者</label>:<span>{{ channel.engraver }}</span> </p>
              <p v-if="channel.designer"><label>设计者</label>:<span>{{ channel.designer }}</span> </p>
              <p v-if="channel.composing"><label>作曲</label>:<span>{{ channel.composing }}</span> </p>
              <p v-if="channel.nationalInheritor"><label>国家级传承人</label>:<span>{{ channel.nationalInheritor }}</span> </p>
              <p v-if="channel.spreadArea"><label>流传地区</label>:<span>{{ channel.spreadArea }}</span> </p>
              <p v-if="channel.location"><label>位置</label>:<span>{{ channel.location }}</span> </p>
              <p v-if="channel.time"><label>时间</label>:<span>{{ channel.time }}</span> </p>
              <p v-if="channel.era"><label>所属时代</label>:<span>{{ channel.era }}</span> </p>
              <p v-if="channel.excavationTime"><label>开凿时间</label>:<span>{{ channel.excavationTime }}</span> </p>
              <p v-if="channel.discoveryTime"><label>发现时间</label>:<span>{{ channel.discoveryTime }}</span> </p>
              <p v-if="channel.cavesNum"><label>现存洞窟数</label>:<span>{{ channel.cavesNum }}</span> </p>
              <p v-if="channel.category"><label>类别</label>:<span>{{ channel.category }}</span> </p>
              <p v-if="channel.material"><label>材质</label>:<span>{{ channel.material }}</span> </p>
              <p v-if="channel.specification"><label>规格</label>:<span>{{ channel.specification }}</span> </p>
              <p v-if="channel.exitTime"><label>出土时间</label>:<span>{{ channel.exitTime }}</span> </p>
              <p v-if="channel.exitPlace"><label>出土地点</label>:<span>{{ channel.exitPlace }}</span> </p>
              <p v-if="channel.collectPlace"><label>收藏地点</label>:<span>{{ channel.collectPlace }}</span> </p>
              <p v-if="channel.constructionTime"><label>兴建时间</label>:<span>{{ channel.constructionTime }}</span> </p>
              <p v-if="channel.completionTime"><label>建成时间</label>:<span>{{ channel.completionTime }}</span> </p>
              <p v-if="channel.constructionArea"><label>建筑面积</label>:<span>{{ channel.constructionArea }}</span> </p>
              <p v-if="channel.landArea"><label>占地面积</label>:<span>{{ channel.landArea }}</span> </p>
              <p v-if="channel.pFullName"><label>全名</label>:<span>{{ channel.pFullName }}</span> </p>
              <p v-if="channel.pOriginalName"><label>原名</label>:<span>{{ channel.pOriginalName }}</span> </p>
              <p v-if="channel.pAlias"><label>又名</label>:<span>{{ channel.pAlias }}</span> </p>
              <p v-if="channel.pFormerName"><label>曾用名</label>:<span>{{ channel.pFormerName }}</span> </p>
              <p v-if="channel.pName"><label>名</label>:<span>{{ channel.pName }}</span> </p>
              <p v-if="channel.oldTranslation"><label>字</label>:<span>{{ channel.oldTranslation }}</span> </p>
              <p v-if="channel.pAnotherName"><label>号</label>:<span>{{ channel.pAnotherName }}</span> </p>
              <p v-if="channel.stageName"><label>艺名</label>:<span>{{ channel.stageName }}</span> </p>
              <p v-if="channel.posthumousName"><label>谥号</label>:<span>{{ channel.posthumousName }}</span> </p>
              <p v-if="channel.identity"><label>身份</label>:<span>{{ channel.identity }}</span> </p>
              <p v-if="channel.hometown"><label>籍贯</label>:<span>{{ channel.hometown }}</span> </p>
              <p v-if="channel.birthDay"><label>出生日期</label>:<span>{{ channel.birthDay }}</span> </p>
              <p v-if="channel.deathDay"><label>去世日期</label>:<span>{{ channel.deathDay }}</span> </p>
              <p v-if="channel.birthPlace"><label>出生地点</label>:<span>{{ channel.birthPlace }}</span> </p>
              <p v-if="channel.deathPlace"><label>去世地点</label>:<span>{{ channel.deathPlace }}</span> </p>
              <p v-if="channel.school"><label>毕业院校</label>:<span>{{ channel.school }}</span> </p>
              <p v-if="channel.representativeWork"><label>代表作品</label>:<span>{{ channel.representativeWork }}</span> </p>
              <p v-if="channel.majorAwards"><label>主要奖项</label>:<span>{{ channel.majorAwards }}</span> </p>
              <p v-for="(value,key) in channel.exts"><label>{{ key }}</label>:<span>{{ value }}</span> </p>
            </div>
          </div>
          <div class="sideText" v-html="higiText(channel.txt)"  @mouseup="mouseup($event)"></div>
        </div>
        <div class="reveal" v-show="reveal" :style="'left:'+pageX+'px'+';'+'top:'+pagey+'px'" @click="vanish">
          <div class="grabble" @click="searchContent"><img src="../../assets/images/details/gra.png"><span>搜索</span></div>
          <div class="copy" @click="copyContent"><img src="../../assets/images/details/fuzhi.png"><span>复制</span></div>
          <div class="tible" @click="collectContent"><img src="../../assets/images/details/shoucang.png"><span>收藏</span></div>
          <div class="minute" @click="addNotes"><img src="../../assets/images/details/biji.png"><span>笔记</span></div>
        </div>
      </div>
      <div style="position:relative;height: 100%; background: rgba(0, 0, 0, 0.28);padding:0px 20px">
        <div class="adrea">
          <div class="virtual frame" v-if="channel.vr"><a :href="channel.vr"></a></div>
          <div class="notecollect frame" :class="noteOpen?'notecollecttive':''" @click="onNote"></div>
          <!-- <div class="deaudio frame" :class="deacyive?'deaudiotive':''" @click="onDeaudio"></div> -->
          <div class="favor frame" :class="isCollect == 1 ? 'favortive' : ''" @click.stop="collect"></div>
          <transition leave-active-class="animated fadeOut">
            <div class="backTop wow fadeIn" v-show="btnFlag" @click="backTop"></div>
          </transition>
        </div>
      </div>
    </div>

    <transition enter-active-class="animated fadeIn">
      <videoModal v-show="videoModal" :videoModal.sync="videoModal"></videoModal>
    </transition>
    <!-- 笔记收藏弹框 -->
    <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
      <div class="system" v-show="sysmodal" :class="sysmodal?'syssgow':''">
        <div class="syscont">
          <div class="syshead">
            <span v-for="(sys,sysindex) in sysdata" :key="sysindex + 1" @click="onmenu(sysindex)" :class="{elect:notective == sysindex}">{{sys.text}}</span>
          </div>
          <div class="jotter">
            <ul v-for="(list,sysindex) in leftmenu" :key="sysindex" v-show="notective == sysindex">
              <li v-for="(item,index) in list.menucont" :key="index">
                <p><span class="quoted">引文</span><label>{{item.txt}}</label></p>
                <p v-show="coll"><span class="lite">笔记</span><label>{{item.note}}</label></p>
                <div class="flex">
                  <span>笔记时间：{{item.createDate}}</span>
                  <label style="cursor: pointer">
                    <img src="../../assets/images/bianji.png" style="margin-right: 20px" v-show="coll" @click="editNote(item,index)">
                    <img src="../../assets/images/shanchu.png" @click="deleteNote(item,index)">
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="sysclode" @click="onclose"><img src="../../assets/images/sysclose.png"></div>
      </div>
    </transition>
    <!--弹框-->
    <div class="boxmodal" v-show="modal">
      <div class="modalcont">
        <div class="modalclose">
          <img src="../../assets/images/tkclose.png" @click="modalClose()">
        </div>
        <div class="cabinet">
          <audio controls :src="media" v-if="media && media.includes('.mp3')"></audio>
          <video muted controls="controls" :src="media" v-else-if="media && media.includes('.mp4')"></video>
          <img :src="media" v-else>
        </div>
        <a :href="moreUrl" v-show="moreUrl !== ''">查看更多>></a>
      </div>
    </div>
  </div>
</template>
<script>
import iHeader from "@/components/Header.vue";
import videoModal from "@/components/videoModal.vue";
import iNavigate from "@/components/navigate.vue";
import { collectItem ,getUserCollectList } from "@/api/userCenter";
import {Message} from "element-ui";
export default {
  name: "punctuation",
  components: {
    iHeader,
    videoModal,
    iNavigate
  },
  data() {
    return {
      reveal: false,
      pageX: 0,
      pagey: 0,
      spread: false,
      hidle: true,
      tunfade: false,
      pointLeft: false,
      seeindex: -1,
      tuning: false,
      tunrowData: [
        {
          children: [
            {
              addClass: "concer",
              text: "听松",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "audio",
            },
            {
              addClass: "concer",
              text: "花欢乐",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "audio",
            },
          ],
        },
        {
          children: [
            {
              addClass: "concer",
              text: "三门峡畅想曲",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "audio",
            },
            {
              addClass: "concer",
              text: "翻身歌",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "audio",
            },
          ],
        },
        {
          children: [
            {
              addClass: "concer",
              text: "洪湖人民的心愿",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "audio",
            },
            {
              addClass: "freque",
              text: "长城随想曲",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "video",
            },
            {
              addClass: "concer",
              text: "二泉映月",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "audio",
            },
          ],
        },
        {
          children: [
            {
              addClass: "freque",
              text: "豫北叙事曲",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "video",
            },
            {
              addClass: "freque",
              text: "虞舜熏风曲",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "video",
            },
          ],
        },
        {
          children: [
            {
              addClass: "freque",
              text: "汉宫秋月",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "video",
            },
            {
              addClass: "freque",
              text: "烛影摇红",
              music: "https://nginx.thatsbooks.com/test.mp3",
              type: "video",
            },
          ],
        },
      ],
      blushPlay: -1,
      /*videoUrl: '',*/
      videoModal:false,
      channel:{},
      makerData:{},
      makerImg: '',
      btnFlag:false,
      scrollTop:0,
      isCollect:'',
      noteOpen:false,
      sysmodal:false,
      notective:0,
      coll:true,
      sysdata:[
        {text:'笔记'},
        {text:'收藏'},
      ],
      leftmenu:[
        {
          menucont:[],
        },
        {
          menucont:[],
        },
      ],
      media:'',
      modal:false,
      moreUrl:''
    };
  },
  props:{
    itemData:Object,
  },
  mounted() {
    this.init();
    this.$nextTick(()=>{
      setTimeout(()=>{
        this.$refs.detailcont.onscroll = this.scrollToTop;
        let content_vm=this.$refs.content;
        content_vm.addEventListener("click",(event)=>{
          const ev = window.event || event;
          if(ev.target.tagName && ev.target.tagName=='IMG'){
            if(ev.target.src){
              let src=ev.target.src;
              if(src.startsWith("data:image/png;base64")){
                return;
              }
              this.previewImg(src);
            }
          }
        });
      },100)
    })
  },
  created() {
    this.getpunct();
  },
  methods: {
    /*图片动画*/
    init() {
      setTimeout(() => {
        this.pointLeft = true;
      }, 500);
    },
    /*展开收起*/
    unfold() {
      this.spread = !this.spread;
      if (!this.spread) {
        this.hidle = true;
        /*   this.tunfade = false;*/
        this.tuning = true;
      } else {
        this.hidle = false;
        /*  this.tunfade = true;*/
        this.tuning = false;
      }
    },
    /*当前锚点显示*/
    cutIn(item) {
      if (item.url === ' '){
        this.seeindex = -1;
      }else {
        this.seeindex = item;
      }

    },
    /*移出当前锚点*/
    packUp() {
      this.seeindex = null;
    },
    /*判断音频视频*/
    board(con, e) {
      if (con.type == "audio") {
        let back = e.currentTarget.lastElementChild;
        if (back.paused) {
          back.play();
          this.blushPlay = con.text;
        } else {
          back.pause();
          this.blushPlay = "-1";
        }
      }else{
        /*this.videoUrl = con.music*/
        this.videoModal = true
      }
    },
    getpunct(){
      let res = this.itemData;
      this.channel = res.data;
      let content = JSON.parse(res.data.contentImgData);
      this.makerData = content.eaim1;
      this.makerImg = this.makerData.canvas;
    },
    /*划线弹框*/
    mouseup(e) {
      let text = window.getSelection().toString()
         this.pageX = e.layerX-60;
      this.pagey = e.layerY+150;
      this.reveal = true;
      if (text == ''){
        this.reveal = false;
      }else{
        this.selectContent=text;
      }
      if(this.pageX > 674){
        this.pageX = e.layerX-290;
      }
    },
    vanish(){
      this.reveal = false;
    },
    //检索内容
    searchContent(){
      if(this.selectContent.length>10){
        Message({
          message: "选择文字过多",
          type: 'error'
        })
        return;
      }
      this.$router.push({
        path: '/search',
        query: {
          inputVal: this.selectContent,
        },
      });
    },
    //复制内容
    copyContent(){
      this.$copyText(this.selectContent);
      Message({
        message: "复制成功",
        type: 'success'
      })
    },
    //收藏内容
    collectContent(){
      this.noteOpen=false;
      this.sysmodal = false;
      this.notective=0;
      let vm=this;
      collectItem(this.channel.uuid, {
        type: 1,
        txt:this.selectContent,
      }).then((res) => {
        if(res.type && res.type=="needToLogin"){
          this.$confirm('此操作需要登录系统，去登陆?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({path: '/login'});
          });
        }else{
          vm.leftmenu[1].menucont=[];
          Message({
            message: "收藏成功",
            type: 'success'
          })
        }
      });
    },
    //添加笔记
    addNotes(){
      this.noteOpen=false;
      this.sysmodal = false;
      this.notective=0;
      if(!this.$store.state.username){
        this.$confirm('此操作需要登录系统，去登陆?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({path: '/login'});
        });
        return;
      }
      let vm=this;
      this.$prompt('请输入笔记内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
      }).then(({ value }) => {
        if(value && value.trim()){
          collectItem(this.channel.uuid, {
            type: 2,
            txt:this.selectContent,
            note:value.trim()
          }).then((res) => {
            if(res.type && res.type=="needToLogin"){
              this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push({path: '/login'});
              });
            }else{
              vm.leftmenu[1].menucont=[];
              Message({
                message: "添加成功",
                type: 'success'
              })
            }
          });
        }
      });
    },
    /*回到顶部*/
    backTop(){
      const that = this;
      let timer = setInterval(()=>{
        let ispeed = Math.floor(-that.scrollTop/5)
        this.$refs.detailcont.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0){
          clearInterval(timer)
        }
      },16)
    },
    scrollToTop(){
      const that = this;
      let scrollTop = this.$refs.detailcont.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 60){
        that.btnFlag = true
      }else {
        that.btnFlag = false
      }
    },
    /*收藏*/
    collect() {
      if (this.isCollect == 1) {
        //取消收藏
        collectItem(this.channel.uuid, {
          type: 0,
          del:1,
        }).then((res) => {
          if(res.type && res.type=="needToLogin"){
            this.$confirm('此操作需要登录系统，去登陆?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({path: '/login'});
            });
          }else{
            this.isCollect = 0
          }

        });
      } else {
        //收藏
        collectItem(this.channel.uuid, {
          type: 0,
        }).then((res) => {
          if(res.type && res.type=="needToLogin"){
            this.$confirm('此操作需要登录系统，去登陆?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({path: '/login'});
            });
          }else{
            this.isCollect = 1
          }
        });
      }
    },
    //打开关闭笔记栏
    onNote(){
      this.getNoteData();
    },
    //获取笔记数据
    getNoteData(){
      getUserCollectList({pageNo: 1,pageSize: 1000,type:2,uuid:this.channel.uuid}).then((res)=>{
        if(res.type && res.type=="needToLogin"){
          this.$confirm('此操作需要登录系统，去登陆?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({path: '/login'});
          });
        }else{
          this.noteOpen=!this.noteOpen;
          this.sysmodal = !this.sysmodal;
          this.leftmenu[0].menucont=res.data.list.map((item)=>{return {
            createDate:item.createDate.substring(0,10),
            id:item.id,
            note:item.note,
            txt:item.txt,
          }});
        }

      })
    },
    //获取收藏数据
    getcollectData(){
      getUserCollectList({pageNo: 1,pageSize: 1000,type:1,uuid:this.channel.uuid}).then((res)=>{
        this.leftmenu[1].menucont=res.data.list.map((item)=>{return {
          createDate:item.createDate.substring(0,10),
          id:item.id,
          note:item.note,
          txt:item.txt,
        }});
      })
    },
    editNote(item,index){
      let vm=this;
      this.$prompt('请输入笔记内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:item.note,
        closeOnClickModal:false,
      }).then(({ value }) => {
        if(value && value.trim()){
          collectItem(this.channel.uuid, {
            type: 2,
            txt:item.txt,
            note:value.trim()
          }).then((res) => {
            if(res.type && res.type=="needToLogin"){
              this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push({path: '/login'});
              });
            }else{
              vm.leftmenu[0].menucont[index].note=value.trim();
              Message({
                message: "修改成功",
                type: 'success'
              })
            }
          });
        }
      });
    },
    deleteNote(item,index){
      let vm=this;
      this.$confirm('此操作为永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        collectItem('del', {
          ids:item.id
        }).then((res) => {
          if(res.type && res.type=="needToLogin"){
            this.$confirm('此操作需要登录系统，去登陆?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({path: '/login'});
            });
          }else{
            if (vm.notective == 1){
              vm.leftmenu[1].menucont.splice(index,1);
            }else {
              vm.leftmenu[0].menucont.splice(index,1);
            }
            Message({
              message: "删除成功",
              type: 'success'
            })
          }
        });
      });
    },
    /*大图弹框*/
    previewImg (url){
      this.$emit('previewImg',url);
    },
    higiText(text) {
      let inputVal=this.$route.query.inputVal;
      if (inputVal && text && text.includes(inputVal)) {
        text=text.replaceAll(inputVal,"<em>"+inputVal+"</em>")
      }
      return text;
    },
    onmenu(sysindex){
      if(sysindex==1 && this.leftmenu[1].menucont.length==0){
        this.getcollectData();
      }
      this.notective = sysindex;
      if (this.notective == 1){
        this.coll = false
      }else {
        this.coll = true
      }
    },
    /*关闭侧边菜单*/
    onclose(){
      this.sysmodal = false;
      this.noteOpen=false;
    },
    getDetail(){
      let res = this.itemData;
      this.tok=res.tok;
      this.channel = res.data;
      this.isCollect=res.isCollect?1:0;
      this.pictureList=res.data.pictureList?res.data.pictureList.map((item)=>{return {url:item.url+'?h=300',name:item.name}}):[];
    },
    /*点击锚点弹框*/
    onModal(index){
      this.media = this.makerData[index].media;
      this.moreUrl = this.makerData[index].url;
      if(this.media && this.media !== ''){
        this.modal = true;
      }else {
        this.modal = false;
      }
    },
    /*关闭锚点弹框*/
    modalClose(){
      this.modal = false
    }
  },
};
</script>

<style scoped>
.punctuation {
  height: 100%;
  background-image: url("../../assets/images/punctuation/bg.png");
  background-size: 100% 100%;
  overflow: hidden;
}
.picter {
  padding-left: 4%;
  padding-top:30px;
  height: 800px;
}
.point {
  position: relative;
  left: -100%;
  opacity: 0;
}
.pointLeft {
  left: 0;
  transition: all 1.6s;
  opacity: 1;
}
.tuning {
  width: 736px;
  margin-left: 76px;
  transition: all 0.5s;
  z-index: 0;
}
.tunfade {
  opacity: 0;
}
.tuning .tunrow {
  position: relative;
  text-align: left;
  margin-bottom: 80px;
}
.tuning p {
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  top: 50%;
}
.tuning .tunrow label {
  font-size: 16px;
  line-height: 18px;
  color: #857176;
  padding: 9px 25px 9px 40px;
  display: inline-block;
  background: #ffffff;
  border-radius: 21px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  z-index: 2;
}
.tuning .tunrow:nth-child(1) label:nth-child(1) {
  margin-left: 55px;
}
.tuning .tunrow:nth-child(1) label:nth-child(2) {
  margin-left: 162px;
}
.tuning .tunrow:nth-child(2) label:nth-child(1) {
  margin-left: 177px;
}
.tuning .tunrow:nth-child(2) label:nth-child(2) {
  margin-left: 144px;
}
.tuning .tunrow:nth-child(3) label:nth-child(1) {
  margin-left: 63px;
}
.tuning .tunrow:nth-child(3) label:nth-child(2) {
  margin-left: 68px;
}
.tuning .tunrow:nth-child(3) label:nth-child(3) {
  margin-left: 81px;
}
.tuning .tunrow:nth-child(4) label:nth-child(1) {
  margin-left: 28px;
}
.tuning .tunrow:nth-child(4) label:nth-child(2) {
  margin-left: 94px;
}
.tuning .tunrow:nth-child(5) label:nth-child(1) {
  margin-left: 144px;
}
.tuning .tunrow:nth-child(5) label:nth-child(2) {
  margin-left: 118px;
}
.tuning .tunrow label:hover {
  /*background: #ff5252;
  color: #FEFEFE;*/
  animation: myfirst linear infinite 2s;
}
@keyframes myfirst {
  0% {
    top: 0px;
  }
  50% {
    top: 7px;
  }
  100% {
    top: 0px;
  }
}
.tuning .concer {
  display: inline-block;
  width: 15px;
  height: 19px;
  margin-right: 5px;
  background-image: url("../../assets/images/punctuation/yinyue.png");
  background-size: 100% 100%;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -9.5px;
  transition: 0.4s;
}
/*.tuning label:hover .concer{
  background-image: url("../assets/images/punctuation/yinyue1.png");
  background-size: 100% 100%;
  width: 40px;
  height: 46px;
  left: 3px;
  top: 9px;
  transform: rotate(360deg);
}*/
.tuning .freque {
  display: inline-block;
  width: 18px;
  height: 16px;
  background-image: url("../../assets/images/punctuation/shipin.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 15px;
  transition: 0.4s;
}
/*.tuning label:hover .freque{
  background-image: url("../assets/images/punctuation/shipin1.png");
  background-size: 100% 100%;
  width: 34px;
  height: 32px;
  left: -4px;
  top: 11px;
  transform: rotate(360deg);
}*/
/*音乐点击样式*/
.tuning label.blush {
  background: #ff5252;
  color: #fefefe;
  animation: myfirst linear infinite 2s;
}
.tuning label.blush .concer {
  background-image: url("../../assets/images/punctuation/yinyue1.png");
  background-size: 100% 100%;
  width: 40px;
  height: 46px;
  left: 3px;
  top: 9px;
  transform: rotate(360deg);
}
.tuning label.blush .freque {
  background-image: url("../../assets/images/punctuation/shipin1.png");
  background-size: 100% 100%;
  width: 34px;
  height: 32px;
  left: -4px;
  top: 11px;
  transform: rotate(360deg);
}
/*右侧弹框*/
.erhuside {
  position: fixed;
  top: 0;
  bottom: 0;
/*  right: -850px;*/
  right: 0;
  z-index: 0;
  transition: 0.3s;
}
.sideImg {
  height: 100%;
  width: 136px;
  position: relative;
  z-index: 0;
}
.sideImg:after {
  content: "";
  background-image: url("../../assets/images/punctuation/xqbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.28;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.sideBg {
  width: 800px;
  height: 100%;
  background: rgba(0, 0, 0, 0.28);
  text-align: left;
  padding: 100px 0px 80px 30px;
  box-sizing: border-box;
  position: relative;
}
.spread {
  right: 0;
}
.sideScrol {
  width: 680px;
  height: 100%;
  overflow-y: auto;
  padding-right: 30px;
}
.sideIcon {
  width: 30px;
  height: 21px;
  background-image: url("../../assets/images/punctuation/zhankai.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.spread .sideIcon {
  transform: rotate(180deg);
}
.packUp span {
  font-size: 26px;
  font-weight: 800;
  color: #d6da00;
  display: inline-block;
  padding-left: 20px;
  line-height: 26px;
}
.packUp {
  justify-content: space-between;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0, -50%);
}
.sideBg h3 {
  font-size: 30px;
  font-weight: bold;
  color: #d6da00;
  padding-bottom: 20px;
}
.sideBg p {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
}
.sideBg >>>::selection {
  background: #FEE851;
  color: #000000;
}
.sideBg>>>::-moz-selection {
  background: #FEE851;
  color: #000000;
}
/*.sideBar {
  font-size: 16px;
  font-weight: bold;
  color: #d6da00;
  padding: 10px 0px 15px 5px;
}
.sideBar span {
  display: inline-block;
  width: 9px;
  height: 9px;
  background: #d6da00;
  transform: rotate(45deg);
  margin-right: 10px;
}*/
/*.exhib {
  width: 421px;
  height: 255px;
  overflow: hidden;
  margin: 30px auto;
}
.exhib img {
  width: 100%;
  height: 100%;
}*/
.sideText >>> p{
  color: #ffffff;
  font-size: 16PX;
  line-height: 32PX;
}
.chartText{
  font-size: 14PX;
}
.sideText >>> .imgTitle{
  text-align: center;
  font-size: 14PX;
}
.sideText >>> .textImg{
  height: 16PX;
  vertical-align: text-top;
  padding: 0;
}
.sideText >>> p img{
  max-width: 100%;
  /*margin: auto;*/
  padding: 10px 0px;
}
.scrol::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background   : #747474 !important;
}
.scrol::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background   : #838181;
}
.pin {
  left: 0;
}
.mongolia {
  position: relative;
  max-width: 800px;
  max-height: 800px;
}
.cavmap{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mongolia img{
  max-width: 100%;
}
/*.mongolia > img {
  max-width: 100%;
}*/
.marker {
  cursor: pointer;
  position: absolute;
  z-index: 4;
}
.dot {
  width: 22px;
  height: 22px;
  background-image: url("../../assets/images/punctuation/miaodian.png");
  background-size: 100% 100%;
}
.caption {
  font-size: 14px;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 10px;
  position: absolute;
  left: 100%;
  max-width: 100px;
  text-align: left;
  min-height: 32px;
  padding-top: 10px;
}
.adrcon{
  display: flex;
  align-items: center;
}
.marker .box_flex{
  position: relative;
  padding-bottom: 7px;
}
.texelink{
  background: #ffffff;
  border-radius: 3px;
  font-size: 14px;
  z-index: 10;
  display: inline-block;
  position: absolute;
  top: 28px;
  width: 100px;
}
.texelink p{
  text-align: left;
  word-break: break-all;
  padding: 5px;
}
.texelink a{
  color: #2464A9;
  font-size: 12px;
  text-decoration: none;
  display: block;
  text-align: right;
  padding: 5px;
}
/*有大小滑上去不消失*/
.makeright .adrcon{
  justify-content: end;
  flex-direction: row-reverse;
}
.makeright .caption {
  text-align: right;
  right: 22px;
  left: auto;
}
.makeright .texelink{
  right: 0;
}
/*光圈*/
.dot::before,.dot::after{
   position: absolute;
   width: 22px;
   height: 22px;
   border-radius: 50%;
   content: '';
   background-color: rgba(255, 255, 255, .9);
   left:0 }
.dot::before{animation: scale 2s infinite; }
.dot::after{animation: scale2 2s infinite; }
@keyframes scale{0%{ transform: scale(1); opacity:.9}100%{ transform: scale(2); opacity: 0;}}
@keyframes scale2{0%{ transform: scale(1);opacity:.9;}100%{ transform: scale(4);opacity:0;}}
/*目录条*/
.labelPage {
  font-size: 14px;
  font-weight: 500;
  color: #b3b3b3;
  text-align: left;
  padding-left: 25px;
  padding-top: 114px;
}

.armila{
  display: flex;
}
.sponsor{
  color: #333333;
  font-size: 16PX;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.sponsor p{
  padding-bottom: 10px;
  min-width: 330px;
  box-sizing: border-box;
  padding-left: 30px;
  line-height: inherit;
}
.sponsor p label{
  display: inline-block;
}
.sponsor p span{
  padding-left: 10px;
  line-height: 32PX;
}
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.ellipsis2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/*划线弹框*/
.reveal span{
  font-size: 16PX;
  color: #666666;
  white-space: nowrap;
}
.reveal{
  position: absolute;
  padding: 5px 15px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  line-height: 32PX;
}
.reveal>div{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20PX;
}
.reveal>div:last-child{
  margin-right: 0;
}
.reveal img{
  padding-right: 4px;
}
/*回到顶部*/
.backTop{
  width: 48px;
  height: 46px;
  background-image: url("../../assets/images/top.png");
  background-size: 100% 100%;
  border-right: 3px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
}
/*右侧笔记图标*/
.notecollect{
  background: #999999 url("../../assets/images/picture/biji.png") no-repeat;
  background-size: 28px 29px;
  background-position: center center;
  top: 71%;
}
.notecollecttive{
  background: #999999 url("../../assets/images/picture/biji-1.png") no-repeat;
  background-size: 28px 29px;
  background-position: center center;
}
.frame{
  width: 48px;
  height: 46px;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
  margin-top: 20px;
}
.favor{
  background: #999999 url("../../assets/images/shoucang.png") no-repeat;
  background-size: 28px 26px;
  background-position: center center;
  margin-bottom: 66px;
}
.favortive{
  background: #999999 url("../../assets/images/shoucang1.png") no-repeat;
  background-size: 28px 26px;
  background-position: center center;
}
/*右侧音频图标*/
.deaudio{
  background: #999999 url("../../assets/images/details/yinpin1.png") no-repeat;
  background-size: 22px 30px;
  background-position: center center;
}
.deaudiotive{
  background: #999999 url("../../assets/images/details/yinpin.png") no-repeat;
  background-size: 22px 30px;
  background-position: center center;
}
/*vr*/
.virtual{
  background: #FF5252 url("../../assets/images/details/VR.png") no-repeat;
  background-size: 28px 13px;
  background-position: center center;
}
.virtual a{
  display: block;
  width: 100%;
  height: 100%;
}
.adrea{
  position: absolute;
  bottom: 15%;
  right: 20px;
}
/*笔记收藏弹框*/
.sysclode{
  width: 50px;
  height: 50px;
  background: rgba(255,255,255,.4);
  border-radius: 50%;
  line-height: 50px;
  margin-left: 18px;
  cursor: pointer;
}
.sysclode img{
  vertical-align: middle;
}
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.system{
  width: 370px;
  height: 750px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 180px;
  left: -100%;
}
.syssgow{
  left: 0;
}
.syscont{
  width: 300px;
  height: 750px;
  background: rgba(255,255,255,.9);
  border-radius: 0px 10px 10px 0px;
  padding: 20px 5px 20px 20px;
  box-sizing: border-box;
}
.syshead span{
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 4px solid transparent;
}
.syshead .elect{
  border-bottom: 4px solid #FF5252;
  color: #FF5252;
}
.syshead span:first-child{
  margin-right: 70px;
}
.jotter{
  margin-top: 10px;
  height: 660px;
  padding-right: 15px;
  overflow-y: auto;
  position: relative;
}
.jotter::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 2px;
}
.jotter::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background   : #8D817A;
}
.jotter::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
.jotter ul{
  position: absolute;
  left: 0;
  top: 0;
  right: 20px;
}
.jotter p{
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
}
.jotter li{
  background: rgba(255,255,255,.9);
  border-radius: 10px;
  padding: 20px 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.jotter li:last-child{
  margin: 0;
}
.jotter p span{
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;
  box-sizing: border-box;
  text-align: center;
  line-height: 15px;
  flex-shrink: 0;
}
.jotter .quoted{
  background: #FF5252;
}
.jotter p label{
  font-size: 14px;
  color: #333333;
  padding-left: 9px;
  line-height: 22px;
  text-align: left;
  word-break: break-all;
}
.jotter .lite{
  background: #808080;
}
.jotter .flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.jotter .flex span{
  font-size: 12px;
  color: #999999;
}
.jotter .flex img{
  width: 18px;
  height: 18px;
}
/*弹框*/
.boxmodal{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  left: 0;
  top: 0;
  z-index: 5;
}
.modalcont{
  position: absolute;
  width: 600px;
  height: 500px;
  background: #ffffff;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -250px;
  padding: 20px;
  box-sizing: border-box;
}
.modalclose{
  text-align: right;
  cursor: pointer;
}
.cabinet{
  width: 100%;
  height: 370px;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cabinet video,.cabinet img{
  max-width: 100%;
  max-height: 100%;
}
.modalcont a{
  color: #2464A9;
  font-size: 12px;
  text-decoration: none;
  display: block;
  text-align: right;
  padding-top: 10px;
}
</style>



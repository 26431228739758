<template>
    <div class="detail">
        <i-header></i-header>
        <div class="labelPage">
          <i-navigate :navigate="channel"></i-navigate>
        </div>
        <div class="seat">
          <div class="detailcont scrol" ref="detailcont">
            <div class=" wow slideInLeft" data-wow-duration=".5s" data-wow-delay=".5s" ref="content">
              <h3>{{channel.name}}</h3>
              <div class="armila">
                <div class="sponsor">
                  <p v-if="channel.fullName"><label>全称</label>:<span>{{ channel.fullName }}</span> </p>
                  <p v-if="channel.originalName"><label>原称</label>:<span>{{ channel.originalName }}</span> </p>
                  <p v-if="channel.abbreviationName"><label>简称</label>:<span>{{ channel.abbreviationName }}</span> </p>
                  <p v-if="channel.commonlyName"><label>俗称</label>:<span>{{ channel.commonlyName }}</span> </p>
                  <p v-if="channel.alsoName"><label>又称</label>:<span>{{ channel.alsoName }}</span> </p>
                  <p v-if="channel.nickName"><label>别称</label>:<span>{{ channel.nickName }}</span> </p>
                  <p v-if="channel.author"><label>作者</label>:<span>{{ channel.author }}</span> </p>
                  <p v-if="channel.creator"><label>创作者</label>:<span>{{ channel.creator }}</span> </p>
                  <p v-if="channel.engraver"><label>刻者</label>:<span>{{ channel.engraver }}</span> </p>
                  <p v-if="channel.designer"><label>设计者</label>:<span>{{ channel.designer }}</span> </p>
                  <p v-if="channel.composing"><label>作曲</label>:<span>{{ channel.composing }}</span> </p>
                  <p v-if="channel.nationalInheritor"><label>国家级传承人</label>:<span>{{ channel.nationalInheritor }}</span> </p>
                  <p v-if="channel.spreadArea"><label>流传地区</label>:<span>{{ channel.spreadArea }}</span> </p>
                  <p v-if="channel.location"><label>位置</label>:<span>{{ channel.location }}</span> </p>
                  <p v-if="channel.time"><label>时间</label>:<span>{{ channel.time }}</span> </p>
                  <p v-if="channel.era"><label>所属时代</label>:<span>{{ channel.era }}</span> </p>
                  <p v-if="channel.excavationTime"><label>开凿时间</label>:<span>{{ channel.excavationTime }}</span> </p>
                  <p v-if="channel.discoveryTime"><label>发现时间</label>:<span>{{ channel.discoveryTime }}</span> </p>
                  <p v-if="channel.cavesNum"><label>现存洞窟数</label>:<span>{{ channel.cavesNum }}</span> </p>
                  <p v-if="channel.category"><label>类别</label>:<span>{{ channel.category }}</span> </p>
                  <p v-if="channel.material"><label>材质</label>:<span>{{ channel.material }}</span> </p>
                  <p v-if="channel.specification"><label>规格</label>:<span>{{ channel.specification }}</span> </p>
                  <p v-if="channel.exitTime"><label>出土时间</label>:<span>{{ channel.exitTime }}</span> </p>
                  <p v-if="channel.exitPlace"><label>出土地点</label>:<span>{{ channel.exitPlace }}</span> </p>
                  <p v-if="channel.collectPlace"><label>收藏地点</label>:<span>{{ channel.collectPlace }}</span> </p>
                  <p v-if="channel.constructionTime"><label>兴建时间</label>:<span>{{ channel.constructionTime }}</span> </p>
                  <p v-if="channel.completionTime"><label>建成时间</label>:<span>{{ channel.completionTime }}</span> </p>
                  <p v-if="channel.constructionArea"><label>建筑面积</label>:<span>{{ channel.constructionArea }}</span> </p>
                  <p v-if="channel.landArea"><label>占地面积</label>:<span>{{ channel.landArea }}</span> </p>
                  <p v-if="channel.pFullName"><label>全名</label>:<span>{{ channel.pFullName }}</span> </p>
                  <p v-if="channel.pOriginalName"><label>原名</label>:<span>{{ channel.pOriginalName }}</span> </p>
                  <p v-if="channel.pAlias"><label>又名</label>:<span>{{ channel.pAlias }}</span> </p>
                  <p v-if="channel.pFormerName"><label>曾用名</label>:<span>{{ channel.pFormerName }}</span> </p>
                  <p v-if="channel.pName"><label>名</label>:<span>{{ channel.pName }}</span> </p>
                  <p v-if="channel.oldTranslation"><label>字</label>:<span>{{ channel.oldTranslation }}</span> </p>
                  <p v-if="channel.pAnotherName"><label>号</label>:<span>{{ channel.pAnotherName }}</span> </p>
                  <p v-if="channel.stageName"><label>艺名</label>:<span>{{ channel.stageName }}</span> </p>
                  <p v-if="channel.posthumousName"><label>谥号</label>:<span>{{ channel.posthumousName }}</span> </p>
                  <p v-if="channel.identity"><label>身份</label>:<span>{{ channel.identity }}</span> </p>
                  <p v-if="channel.hometown"><label>籍贯</label>:<span>{{ channel.hometown }}</span> </p>
                  <p v-if="channel.birthDay"><label>出生日期</label>:<span>{{ channel.birthDay }}</span> </p>
                  <p v-if="channel.deathDay"><label>去世日期</label>:<span>{{ channel.deathDay }}</span> </p>
                  <p v-if="channel.birthPlace"><label>出生地点</label>:<span>{{ channel.birthPlace }}</span> </p>
                  <p v-if="channel.deathPlace"><label>去世地点</label>:<span>{{ channel.deathPlace }}</span> </p>
                  <p v-if="channel.school"><label>毕业院校</label>:<span>{{ channel.school }}</span> </p>
                  <p v-if="channel.representativeWork"><label>代表作品</label>:<span>{{ channel.representativeWork }}</span> </p>
                  <p v-if="channel.majorAwards"><label>主要奖项</label>:<span>{{ channel.majorAwards }}</span> </p>
                  <p v-for="(value,key) in channel.exts" :key="key"><label>{{ key }}</label>:<span>{{ value }}</span> </p>

                </div>
              </div>
              <div class="scribe">
                <div  @mousedown="mousedown($event)"  @mouseup="mouseup($event)" v-html="higiText(channel.txt)"></div>
                <div class="reveal" v-show="reveal" :style="'left:'+pageX+'px'+';'+'top:'+pagey+'px'" @click="vanish">
                  <div class="grabble" @click="searchContent"><img src="../../assets/images/details/gra.png"><span>搜索</span></div>
                  <div class="copy" @click="copyContent"><img src="../../assets/images/details/fuzhi.png"><span>复制</span></div>
                  <div class="tible" @click="collectContent"><img src="../../assets/images/details/shoucang.png"><span>收藏</span></div>
                  <div class="minute" @click="addNotes"><img src="../../assets/images/details/biji.png"><span>笔记</span></div>
                </div>
              </div>
              <!--没有权限-->
              <div class="limits" v-if="!channel.permit">
                   <img src="../../assets/images/details/quanxian.png">没有权限
              </div>
              <div class="clauseu" v-if="pictureList.length" style="border: none">
                <div v-for="(item,index) in pictureList" :key="index" style="text-align: center;">
                  <img :src="item.url"  style="max-width: 100%;"/>
                  <div class="chartText">{{item.name}}</div>
                </div>
              </div>

              <div class="clauseu" v-if="recommends">
                  <p>相关条目</p>
                  <ul>
                    <li v-for="(clau, clauindex) in clauDate" :key="clauindex" @click="toItemInfo(clau.uuid)">
                      {{ clau.name }}
                    </li>
                  </ul>
                </div>
            </div>
          </div>
          <div style="position:relative;">
            <div class="adrea">
              <div class="virtual frame" v-if="channel.vr"><a :href="channel.vr"></a></div>
              <div class="notecollect frame" :class="noteOpen?'notecollecttive':''" @click="onNote"></div>
               <!--<div class="deaudio frame" :class="deacyive?'deaudiotive':''" @click="onDeaudio"></div>-->
              <div class="favor frame" :class="isCollect == 1 ? 'favortive' : ''" @click.stop="collect"></div>
              <transition leave-active-class="animated fadeOut">
                <div class="backTop wow fadeIn" v-show="btnFlag" @click="backTop"></div>
              </transition>
            </div>
          </div>

        </div>
        <!-- 笔记收藏弹框 -->
        <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
          <div class="system" v-show="sysmodal" :class="sysmodal?'syssgow':''">
            <div class="syscont">
              <div class="syshead">
                <span v-for="(sys,sysindex) in sysdata" :key="sysindex + 1" @click="onmenu(sysindex)" :class="{elect:notective == sysindex}">{{sys.text}}</span>
              </div>
              <div class="jotter">
                <ul v-for="(list,sysindex) in leftmenu" :key="sysindex" v-show="notective == sysindex">
                  <li v-for="(item,index) in list.menucont" :key="index">
                    <p><span class="quoted">引文</span><label>{{item.txt}}</label></p>
                    <p v-show="coll"><span class="lite">笔记</span><label>{{item.note}}</label></p>
                    <div class="flex">
                      <span>笔记时间：{{item.createDate}}</span>
                      <label style="cursor: pointer">
                        <img src="../../assets/images/bianji.png" style="margin-right: 20px" v-show="coll" @click="editNote(item,index)">
                        <img src="../../assets/images/shanchu.png" @click="deleteNote(item,index)">
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="sysclode" @click="onclose"><img src="../../assets/images/sysclose.png"></div>
          </div>
        </transition>
        <audio ref="baiduAudio"></audio>
    </div>
</template>
<script>
import { WOW } from "wowjs";
import iHeader from '@/components/Header.vue';
import iNavigate from "@/components/navigate.vue";
import { collectItem ,getUserCollectList } from "@/api/userCenter";
import { myBtts } from "../../utils/baidu_tts_cors";
import {Message} from "element-ui";
export default({
    name:'detail',
    components: {
        iHeader,
        iNavigate
    },
    data() {
        return {
            reveal: false,
            pageX: 0,
            pagey: 0,
            clauDate: [],
            vrModal:false,
            audioModal: false,
            channel: {},
            btnFlag:false,
            scrollTop:0,
            selectContent:'',
            isCollect:'',
            tok:'',//百度语音token
            audio:'',//语音标签
            deacyive:false,
            sysmodal:false,
            recommends:false,
            notective:0,
            coll:true,
            pictureList:[],
            noteOpen:false,
            sysdata:[
              {text:'笔记'},
              {text:'收藏'},
            ],
            leftmenu:[
              {
                menucont:[],
              },
              {
                menucont:[],
              },
            ],
        }
    },
    props:{
      itemData:Object,
    },
    watch: {
      '$route' (to, from) {
        this.$router.go(0);
      }
    },
    methods: {
      /*大图弹框*/
      previewImg (url){
        this.$emit('previewImg',url);
      },
      onmenu(sysindex){
        if(sysindex==1 && this.leftmenu[1].menucont.length==0){
          this.getcollectData();
        }
        this.notective = sysindex;
        if (this.notective == 1){
          this.coll = false
        }else {
          this.coll = true
        }
      },
       /*收藏*/
      collect() {
          if (this.isCollect == 1) {
            //取消收藏
            collectItem(this.channel.uuid, {
              type: 0,
              del:1,
            }).then((res) => {
              if(res.type && res.type=="needToLogin"){
                this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$router.push({path: '/login'});
                });
              }else{
                this.isCollect = 0
              }

            });
          } else {
            //收藏
            collectItem(this.channel.uuid, {
              type: 0,
            }).then((res) => {
              if(res.type && res.type=="needToLogin"){
                this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$router.push({path: '/login'});
                });
              }else{
                this.isCollect = 1
              }
            });
          }
      },
      /*关闭侧边菜单*/
      onclose(){
          this.sysmodal = false;
          this.noteOpen=false;
      },
      mousedown(){
      },
      mouseup(e) {
        let clientWidth=document.documentElement.clientWidth || document.body.clientWidth;
            let text = window.getSelection().toString()
            this.pagey = e.layerY+20;
            this.reveal = true;
            if (text == ''){
              this.reveal = false;
            }else{
              this.selectContent=text;
            }

            if(clientWidth>=1920){
              if(e.layerX > 600){
                  this.pageX = 520;
                }else{
                  this.pageX = e.layerX-60;
                }
            }else if(clientWidth<1920){
              if(e.layerX > 400){
                this.pageX = 360;
              }else{
                this.pageX = e.layerX-60;
              }
            }

      },
      vanish(){
            this.reveal = false;
      },
      getDetail(){
        let res = this.itemData;
        this.tok=res.tok;
        this.channel = res.data;
        let str=res.data.recommendNames;
        if(str!=null&&str!="") {
          this.recommends=true;
          let arr = str.substring(1, str.length - 1).split("],[");
          this.clauDate = arr.map((item) => {
            return {uuid: item.split(",")[0], name: item.split(",")[1]}
          });
        }
        this.isCollect=res.isCollect?1:0;
        this.pictureList=res.data.pictureList?res.data.pictureList.map((item)=>{return {url:item.url+'?h=500',name:item.name}}):[];
      },
      higiText(text) {
        let inputVal=this.$route.query.inputVal;
        if (inputVal && text && text.includes(inputVal)) {
          text=text.replaceAll(inputVal,"<em>"+inputVal+"</em>")
        }
        return text;
      },
        /*回到顶部*/
      backTop(){
            const that = this;
            let timer = setInterval(()=>{
              let ispeed = Math.floor(-that.scrollTop/5)
              this.$refs.detailcont.scrollTop = that.scrollTop + ispeed
              if (that.scrollTop === 0){
                clearInterval(timer)
              }
            },16)
      },
      scrollToTop(){
            const that = this;
            let scrollTop = this.$refs.detailcont.scrollTop
            that.scrollTop = scrollTop
            if (that.scrollTop > 60){
              that.btnFlag = true
            }else {
              that.btnFlag = false
            }
      },
      toItemInfo(uuid){
         this.$refs.baiduAudio.src='';
         this.audio='';
         this.deacyive=false;
         this.$router.push({
          name: 'item',
          query: {
            sortId: uuid,
          },
        });
      },
      //检索内容
      searchContent(){
        if(this.selectContent.length>10){
          Message({
            message: "选择文字过多",
            type: 'error'
          })
          return;
        }
          this.$router.push({
              path: '/search',
              query: {
                inputVal: this.selectContent,
              },
          });
      },
      //复制内容
      copyContent(){
        this.$copyText(this.selectContent);
        Message({
          message: "复制成功",
          type: 'success'
        })
      },
      //收藏内容
      collectContent(){
        this.noteOpen=false;
        this.sysmodal = false;
        this.notective=0;
        let vm=this;
        collectItem(this.channel.uuid, {
              type: 1,
              txt:this.selectContent,
        }).then((res) => {
              if(res.type && res.type=="needToLogin"){
                this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$router.push({path: '/login'});
                });
              }else{
                vm.leftmenu[1].menucont=[];
                Message({
                  message: "收藏成功",
                  type: 'success'
                })
              }
        });
      },
      //添加笔记
      addNotes(){
        this.noteOpen=false;
        this.sysmodal = false;
        this.notective=0;
        if(!this.$store.state.username){
          this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
          }).then(() => {
                      this.$router.push({path: '/login'});
          });
          return;
        }
        let vm=this;
        this.$prompt('请输入笔记内容', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
        }).then(({ value }) => {
          if(value && value.trim()){
            collectItem(this.channel.uuid, {
              type: 2,
              txt:this.selectContent,
              note:value.trim()
            }).then((res) => {
                  if(res.type && res.type=="needToLogin"){
                    this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      this.$router.push({path: '/login'});
                    });
                  }else{
                    vm.leftmenu[1].menucont=[];
                    Message({
                      message: "添加成功",
                      type: 'success'
                    })
                  }
            });
          }
        });
      },
      /**/
      onDeaudio(){
        // this.deacyive = !this.deacyive;
        let content=this.$refs.content.innerText.replaceAll("\n","");
        this.audio=this.$refs.baiduAudio;
        if(!content || !content.trim()){
          alert("内容为空，不可播放语音！");
          return;
        }
        if(this.audio.getAttribute("src")){
          if(this.audio.paused){
            this.audio.play();
            this.deacyive=true;
          }else{
            this.audio.pause();
            this.deacyive=false;
          }
          return;
        }
        var that=this;
        myBtts(this.audio,{
                tex:content,
                tok: this.tok,
                spd: 5,
                pit: 5,
                vol:  5,
                per: 0
        }, {
                volume: 0.3,
                timeout: 10000,
                hidden: true,
                onInit: function (htmlAudioElement) {

                },
                onSuccess: function(htmlAudioElement) {
                    that.deacyive=true;
                    htmlAudioElement.play();
                },
                onError: function(text) {
                    alert(text)
                },
                onTimeout: function () {
                    alert('timeout')
                }
        });
      },
      //打开关闭笔记栏
      onNote(){
        this.getNoteData();
      },
      //获取笔记数据
      getNoteData(){
        getUserCollectList({pageNo: 1,pageSize: 1000,type:2,uuid:this.channel.uuid}).then((res)=>{
          if(res.type && res.type=="needToLogin"){
            this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
            }).then(() => {
                this.$router.push({path: '/login'});
            });
          }else{
            this.noteOpen=!this.noteOpen;
            this.sysmodal = !this.sysmodal;
            this.leftmenu[0].menucont=res.data.list.map((item)=>{return {
              createDate:item.createDate.substring(0,10),
              id:item.id,
              note:item.note,
              txt:item.txt,
            }});
          }

        })
      },
      //获取收藏数据
      getcollectData(){
        getUserCollectList({pageNo: 1,pageSize: 1000,type:1,uuid:this.channel.uuid}).then((res)=>{
          this.leftmenu[1].menucont=res.data.list.map((item)=>{return {
            createDate:item.createDate.substring(0,10),
            id:item.id,
            note:item.note,
            txt:item.txt,
          }});
        })
      },
      editNote(item,index){
        let vm=this;
        this.$prompt('请输入笔记内容', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue:item.note,
          closeOnClickModal:false,
        }).then(({ value }) => {
          if(value && value.trim()){
            collectItem(this.channel.uuid, {
              type: 2,
              txt:item.txt,
              note:value.trim()
            }).then((res) => {
                  if(res.type && res.type=="needToLogin"){
                    this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      this.$router.push({path: '/login'});
                    });
                  }else{
                    vm.leftmenu[0].menucont[index].note=value.trim();
                    Message({
                      message: "修改成功",
                      type: 'success'
                    })
                  }
            });
          }
        });
      },
      deleteNote(item,index){
        let vm=this;
        this.$confirm('此操作为永久删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          collectItem('del', {
              ids:item.id
          }).then((res) => {
                if(res.type && res.type=="needToLogin"){
                  this.$confirm('此操作需要登录系统，去登陆?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.$router.push({path: '/login'});
                  });
                }else{
                  if (vm.notective == 1){
                    vm.leftmenu[1].menucont.splice(index,1);
                  }else {
                    vm.leftmenu[0].menucont.splice(index,1);
                  }
                  Message({
                    message: "删除成功",
                    type: 'success'
                  })
                }
          });
        });
      }
    },
    created() {
      this.getDetail();
    },
    mounted() {
      new WOW().init();
      this.$nextTick(()=>{
        setTimeout(()=>{
          this.$refs.detailcont.onscroll = this.scrollToTop;
          let content_vm=this.$refs.content;
          content_vm.addEventListener("click",(event)=>{
            const ev = window.event || event;
            if(ev.target.tagName && ev.target.tagName=='IMG'){
              if(ev.target.src){
                let src=ev.target.src;
                if(src.startsWith("data:image/png;base64")){
                  return;
                }
                this.previewImg(src);
              }
            }
          });
        },100)
      })
    }
})
</script>
<style scoped>
.chartText {
  text-align: center;
  color: #333333;
  font-size: 14PX;
  padding-top: 10px;
  padding-bottom: 30px;
  left: 50%;
  bottom: -100px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  bottom: -60px;
}
/*笔记收藏弹框*/
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.system{
  width: 370px;
  height: 750px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 180px;
  left: -100%;
}
.syssgow{
  left: 0;
}
.syscont{
  width: 300px;
  height: 750px;
  background: rgba(255,255,255,.26);
  border-radius: 0px 10px 10px 0px;
  padding: 20px 5px 20px 20px;
  box-sizing: border-box;
}
.syshead span{
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 4px solid transparent;
}
.syshead .elect{
  border-bottom: 4px solid #FF5252;
  color: #FF5252;
}
.syshead span:first-child{
  margin-right: 70px;
}
.jotter{
  margin-top: 10px;
  height: 660px;
  padding-right: 15px;
  overflow-y: auto;
  position: relative;
}
.jotter::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 2px;
}
.jotter::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background   : #8D817A;
}
.jotter::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
.jotter ul{
  position: absolute;
  left: 0;
  top: 0;
  right: 20px;
}
.jotter p{
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
}
.jotter li{
  background: rgba(255,255,255,.9);
  border-radius: 10px;
  padding: 20px 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.jotter li:last-child{
  margin: 0;
}
.jotter p span{
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;
  box-sizing: border-box;
  text-align: center;
  line-height: 15px;
  flex-shrink: 0;
}
.jotter .quoted{
  background: #FF5252;
}
.jotter p label{
  font-size: 14px;
  color: #333333;
  padding-left: 9px;
  line-height: 22px;
  text-align: left;
  word-break: break-all;
}
.jotter .lite{
  background: #808080;
}
.jotter .flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.jotter .flex span{
  font-size: 12px;
  color: #999999;
}
.jotter .flex img{
  width: 18px;
  height: 18px;
}
@media (max-width: 1600px) {
  .jotter .flex label{
    padding-top: 10px;
  }
}
.sysclode{
  width: 50px;
  height: 50px;
  background: rgba(255,255,255,.13);
  border-radius: 50%;
  line-height: 50px;
  margin-left: 18px;
  cursor: pointer;
}
.sysclode img{
  vertical-align: middle;
}
.frame{
  width: 48px;
  height: 46px;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
}
/*收藏*/
.favor{
  background: #999999 url("../../assets/images/shoucang.png") no-repeat;
  background-size: 28px 26px;
  background-position: center center;
}
.favortive{
  background: #999999 url("../../assets/images/shoucang1.png") no-repeat;
  background-size: 28px 26px;
  background-position: center center;
}
.detail{
  min-height: 100%;
  background-image: url("../../assets/images/details/bg.png");
  background-size: 100% 100%;
}
.detailcont{
  width: 900px;
  min-height: 100%;
  height: 100vh;
  background: rgba(255,255,255,.75);
  overflow-y: auto;
  padding: 70px 60px 70px 70px;
  box-sizing: border-box;
  text-align: left;
}
.detailcont h3{
  font-size: 30px;
  color: #333333;
  padding-bottom: 20px;
}
.armila{
  display: flex;
}
.sponsor{
  color: #333333;
  font-size: 16PX;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.sponsor p{
  padding-bottom: 10px;
  min-width: 380px;
  box-sizing: border-box;
  padding-left: 30px;
  line-height: 30PX;
  display: inline-table;
}
.sponsor p label{
  display: inline-block;
}
.sponsor p span{
  padding-left: 10px;
}
.scribe{
  width:100%;
  margin: auto;
  padding-bottom: 20px;
  color: #333333;
  font-size: 16PX;
  line-height: 32PX;
  position: relative;
}
.scribe >>> .imgTitle{
  text-align: center;
  font-size: 14PX;
}
.scribe >>> .textImg{
  height: 16PX;
  vertical-align: text-top;
}
/*.scribe >>> p{
  text-indent: 32px;
}*/
.scribe >>> p img{
  position: relative;
/*  left: 50%;
  transform: translateX(-50%);
  margin-left: -32px;*/
  max-width: 100%;
}
/*.scribe >>> pics img{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}*/
>>>::selection {
  background: #FEE851;
}
>>>::-moz-selection {
  background: #FEE851;
}
.reveal span{
  font-size: 16PX;
  color: #666666;
  white-space: nowrap;
}
.reveal{
  position: absolute;
  padding: 5px 15px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.reveal>div{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20PX;
}
.reveal>div:last-child{
  margin-right: 0;
}
.reveal img{
  padding-right: 4px;
}
.seat{
  display: flex;
  justify-content: flex-start;
  width: 970px;
/*  position: relative;*/
  margin: auto;
}
/*相关条目*/
.clauseu{
  border-top: 1px solid rgba(255,255,255,.14);
}
.clauseu p {
  font-size: 20px;
  color: #1a1a1a;
  text-align: left;
  padding: 30px 0px 20px 0px;
}
.clauseu ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.clauseu li {
  background: #ffffff;
  border-radius: 0px 6px 6px 0px;
  padding: 11px 19px 11px 16px;
  font-size: 14px;
  color: #0404c4;
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border-left: 4px solid #FF5252;
}
/*弹框按钮*/
.sider {
  position: fixed;
  right: 30px;
  top: 182px;
}
.sider p {
  display: block;
  width: 54px;
  height: 54px;
  border: 2px solid rgba(255, 255, 255, 0.33);
  border-radius: 50%;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.4s all;
  text-align: center;
  line-height: 54px;
}
.sider p:hover {
  transform: rotate(360deg);
}
.sider span{
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}
.sear span{
  width: 18px;
  height: 18px;
  background-image: url("../../assets/images/details/sousuo.png");
  background-size: 100% 100%;
}
.videos span{
  width: 18px;
  height: 16px;
  background-image: url("../../assets/images/details/shipin.png");
  background-size: 100% 100%;
}
.audios span{
  width: 18px;
  height: 23px;
  background-image: url("../../assets/images/details/yinyue.png");
  background-size: 100% 100%;
}
.vr span{
  width: 22px;
  height: 10px;
  background-image: url("../../assets/images/details/VR.png");
  background-size: 100% 100%;
}
/*目录*/
.labelPage {
  font-size: 14px;
  font-weight: 500;
  color: #b3b3b3;
  text-align: left;
  padding-left: 25px;
  padding-top: 114px;
  position: absolute;
}
.labelPage a {
  outline: none;
  text-decoration: none;
}
.labelPage span {
  cursor: pointer;
}
.labelPage span:last-child {
  color: #ffffff;
}
.labelPage a span:last-child {
  color: #b3b3b3;
}
/*回到顶部*/
.backTop{
  position: absolute;
  width: 48px;
  height: 46px;
  background-image: url("../../assets/images/top.png");
  background-size: 100% 100%;
  border-right: 3px;
  cursor: pointer;
  /*right: 0;*/
  margin-left: 20px;
  margin-top: 20px;
}
/*右侧笔记图标*/
.notecollect{
  background: #999999 url("../../assets/images/picture/biji.png") no-repeat;
  background-size: 28px 29px;
  background-position: center center;
}
.notecollecttive{
  background: #999999 url("../../assets/images/picture/biji-1.png") no-repeat;
  background-size: 28px 29px;
  background-position: center center;
}

/*右侧音频图标*/
.deaudio{
  background: #999999 url("../../assets/images/details/yinpin1.png") no-repeat;
  background-size: 22px 30px;
  background-position: center center;
}
.deaudiotive{
  background: #999999 url("../../assets/images/details/yinpin.png") no-repeat;
  background-size: 22px 30px;
  background-position: center center;
}
/*vr*/
.virtual{
  background: #FF5252 url("../../assets/images/details/VR.png") no-repeat;
  background-size: 28px 13px;
  background-position: center center;
}
.virtual a{
  display: block;
  width: 100%;
  height: 100%;
}
.adrea{
  position: absolute;
  bottom: 15%;
}
/*沒有权限*/
.limits{
  padding: 20px 0px;
  border-top:1px solid rgba(230,230,230,.74);
  border-bottom:1px solid rgba(230,230,230,.74);
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333333;
}
.limits img{
  width: 20px;
  height: 25px;
  margin-right: 10px;
}
</style>

<template>
  <div style="height: 100%;">
    <my-multi-media v-if="templateName=='multiMedia'" @previewImg="previewImg" @previewImgList="previewImgList" :itemData="itemData"></my-multi-media>
    <detail v-else-if="templateName=='detail'" @previewImg="previewImg" :itemData="itemData"></detail>
    <punctuation v-else-if="templateName=='punctuation'" @previewImg="previewImg" :itemData="itemData"></punctuation>
    <!--下载图标-->
   <!-- <div class="upload" v-show="upload">
      <img class="downloading" style="display: none" src="../../assets/images/jiazaizhong.png">
      <img class="downloaded" @click="down" src="../../assets/images/xiazai.png">
    </div>-->
    <div class="preName" v-if="preName">{{pictureData[pictureIndex].name}}</div>
  </div>

</template>

<script>
import {getItem,getPreItem} from '@/api/home';
import MyMultiMedia from './multiMedia.vue';
import Detail from './defaultDetail.vue';
import Punctuation from './mao_dian.vue';
import {getImgDownloadUrl} from "@/api/home"
import $ from "jquery";

export default {
  name: "list",
  components: {
    Detail,//文字详情页
    Punctuation,//锚点详情页
    MyMultiMedia//三合一详情页
  },
  data() {
    return {
      templateName: "",
      currentBigImg:'',
      itemData:"",
      preName:false,
      pictureList:[],
      pictureData:[],
      pictureIndex: 0,
    };
  },
  created() {
    if(this.$route.path.indexOf("pre")>-1){
      //预览模式
      getPreItem(this.$route.query.z).then((res) => {
        if(res.redirect=="index")
          this.$router.push({ path: "/index"});
        this.itemData=res;
        this.templateName =res.data.templateName;
        this.pictureData = res.data.pictureList || [];
        this.pictureList= this.pictureData.map((item)=>{return item.url.split("?")[0]+"?p=0"});
      });
    }else{
      getItem(this.$route.query.sortId).then((res) => {
        if(res.redirect=="index")
          this.$router.push({ path: "/index"});
        this.itemData=res;
        this.templateName =res.data.templateName;
        this.pictureData = res.data.pictureList|| [];
        this.pictureList= this.pictureData.map((item)=>{return item.url.split("?")[0]+"?p=0"});
      });
    }
  },
  methods: {
    previewImgList(index){
      this.preName = true
      this.pictureIndex = index
      this.$hevueImgPreview({
        multiple: true,
        nowImgIndex: index,
        imgList: this.pictureList
      });
      this.$nextTick(()=> {
        $(".he-close-icon").click(() => {
          this.preName = false
        });
        this.arrowRight();
        this.arrowLeft();
      })
    },
    /*切换大图弹框文字*/
    arrowRight(){
      $('.arrow-right').click(()=>{
        this.pictureIndex ++;
        if(this.pictureIndex > this.pictureData.length-1){
          this.pictureIndex = 0;
        }
      })
    },
    arrowLeft () {
      $('.arrow-left').click(()=>{
        this.pictureIndex --;
        if(this.pictureIndex < 0){
          this.pictureIndex = this.pictureData.length - 1;
        }
      })
    },
    /*大图弹框*/
    previewImg (url){
      this.currentBigImg=url.split("?")[0]+"?p=0";
      this.$hevueImgPreview({
        url:url.split("?")[0]+"?p=0",
      });
    },
    down(){
      let imageUrl=$("#hevue-imgpreview-wrap").find("img").attr("src");
      let vm=this;
      $(".downloaded").hide();
      $(".downloading").show();
      let url=imageUrl.split("?")[0];
      getImgDownloadUrl(vm.$route.query.sortId,url).then((res)=>{
        if(res.code==200){
          this.downloadRemoteImg(res.url,'download.png',this.downloading);
        }
      })
    },
    downloadRemoteImg(url, filename,downloading) {
      this.getBlob(url, function(blob) {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename);
        } else {
          var link = document.createElement('a');
          var body = document.querySelector('body');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.style.display = 'none';
          body.appendChild(link);
          link.click();
          body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
          downloading=false;
        };
      });
    },
    getBlob(url,cb) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onloadend = function () {
        $(".downloading").hide();
        $(".downloaded").show();
      };
      xhr.onload = function() {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
  },
};
</script>

<style scoped>
.preName{
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
}
.inload{
  animation: rotate 3s linear infinite;
}
@keyframes rotate {
  from{-webkit-transform: rotate(0deg)}
  to{-webkit-transform: rotate(360deg)}
}
</style>
<style>
/* 预览图片 */
.hevue-imgpreview-wrap{
  background: rgba(0, 0, 0, 0.42);
}
.hevue-imgpreview-wrap .he-img-wrap{
  width: 100% ;
  height:100% ;
  background: rgba(26, 26, 26, 0.97);
  padding: 0 140px 0 140px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  left: 0;
  top: 0;
  overflow: hidden;
}
.hevue-imgpreview-wrap .he-img-wrap img{
  cursor: move;
  background: #ffffff;
  max-width: 80% !important;
  max-height: 80% !important;
}
.hevue-imgpreview-wrap .he-control-bar-wrap{
  display: none;
}
.hevue-imgpreview-wrap .he-close-icon {
    color: white;
    font-size: 30px;
}
</style>

<template>
  <div>
    <!--视频弹框-->
    <div class="videoModal modalFixed">
      <div class="locat">
        <div class="fixClose" @click="videoModalHide"><img src="../assets/images/cha.png"></div>
        <div class="modalWidth">
          <div class="box_flex">
            <div class="leftArrow" @click="arrowClick('left')"><img src="../assets/images/video/prev.png"></div>
            <el-carousel
                :interval="4000"
                :autoplay="false"
                arrow="never"
                :loop="false"
                :initial-index="videoIndex"
                @change="onSlideChange"
                trigger="click"
                ref="cardShow"
                indicator-position="none"
            >
              <el-carousel-item v-for="(list, index) in swiperDate" :key="index">
                <div
                    @mouseenter="showPause(true)"
                    @mouseleave="showPause(false)"
                    style="height: 100%"
                >
                  <div
                      :class="'vodeoPlay' + [list.play ? ' vodeoPause' : '']"
                      @click="play(index)"
                      v-show="(showIcon && list.play) || !list.play"
                  ></div>
                  <video
                      loop
                      muted
                      controls="controls"
                      :ref="'video' + index"
                      :poster="list.titleImg"
                  >
                    <source :src="list.url" />
                  </video>
                  <div
                      class="marker"
                      v-for="(tag, idx) in showTagList"
                      :style="{ right: tag.x, bottom: tag.y }"
                      :key="idx"
                      v-show="videoIndex == index"
                  >
                    <div class="box_flex">
                      <div class="caption">
                        <span>{{ tag.msg }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="rightArrow"  @click="arrowClick('right')"><img src="../assets/images/video/next.png"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "videoModal",
  components: {
  },
  props:{
    detacont:Array
  },
  data(){
    return{
      swiperDate: [],
      showTagList: [],
      videoIndex: 1,
      showIcon: false,
      videoIns: {},
      oldTime: 0,
      isChange: false,
    }
  },
  mounted() {
  },
  created() {
    this.videoData();
  },
  methods:{
    videoData(){
      this.swiperDate = this.detacont;
    },

    showPause(show) {
      this.showIcon = show;
    },
    play(index) {
      let videoIns = this.initVideo(index);
      if (videoIns.paused == true) {
        videoIns.play();
      } else {
        videoIns.pause();
      }
    },
    initVideo(index) {
      let videoRef = `video${index}`;
      if (this.videoIns[videoRef]) {
        return this.videoIns[videoRef];
      } else {
        this.videoIndex = index;
        this.videoIns[videoRef] = this.$refs[`video${index}`][0];
        this.videoIns[videoRef].addEventListener(
            "timeupdate",
            this.videoTimeUpdate
        );
        this.videoIns[videoRef].addEventListener("play", this.videoPlay);
        this.videoIns[videoRef].addEventListener("pause", this.videoPause);
        return this.videoIns[videoRef];
      }
    },
    // 视频播放
    videoPlay() {
      this.showTagList = [];
      let index = this.videoIndex;
      this.$set(this.swiperDate, index, {
        ...this.swiperDate[index],
        play: true,
      });
    },
    // 视频暂停
    videoPause() {
      let index = this.videoIndex;
      this.$set(this.swiperDate, index, {
        ...this.swiperDate[index],
        play: false,
      });
    },
    // 视频更新
    videoTimeUpdate(e) {
      let currentTime = Math.floor(e.target.currentTime);
      if (this.oldTime != currentTime) {
        this.showTag(currentTime, this.videoIndex);
      }
      this.oldTime = currentTime;
    },
    showTag(currentTime, index) {
      if (this.swiperDate[index].tagList[currentTime]) {
        this.showTagList = this.swiperDate[index].tagList[currentTime];
        this.initVideo(index).pause();
      }
    },
    onSlideChange(index) {
      this.showTagList = [];
      this.oldTime = 0;
      this.isChange = false;
      this.videoIndex = index;
      this.initVideo(index);
    },
    /*左右按钮*/
    arrowClick(val){
      if (val === 'right'){
        this.$refs.cardShow.next()
      }else {
        this.$refs.cardShow.prev()
      }
    },
    /*视频弹框*/
    videoModalHide(){
      this.$emit('update:videoModal', false);
    },
  }
}
</script>

<style scoped>
/*弹框*/
.modalFixed{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background:rgba(0,0,0,.74) ;
  z-index: 9;
}
.modalWidth{
  height: 640px;
  position: relative;;
}
.locat{
  position: relative;
  left: 50%;
  margin-left: -569px;
  width: 1138px;
  top: 150px
}
.fixClose{
  width: 74px;
  height: 74px;
  background: rgba(255,255,255,.15);
  border-radius: 50%;
  text-align: center;
  line-height: 74px;
  position: absolute;
  right: -100px;
  top: -30px;
  cursor: pointer;
}
.fixClose img{
  width: 22px;
  height: 22px;
  vertical-align: middle;
}
/*视频弹框*/
.marker {
  cursor: pointer;
  position: absolute;
  z-index: 999999;
}
.vodeoPlay {
  background-image: url("../assets/images/video/play.png");
  width: 80px;
  height: 80px;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 3;
}
.caption {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
}
.caption span {
  background: #668b78;
  display: inline-block;
  padding: 10px;
  border-radius: 4px 0px 0px 4px;
  border-right: 5px solid #4d6f5e;
  box-sizing: border-box;
}
video {
  width: 100%;
  height: 100%;
}
/*.modalWidth .swiper-container{
  height: 100%;
}
.modalWidth .swiper-button-prev,
.modalWidth .swiper-button-next {
  top: 50%;
  margin-top: -65px;
  width: 82px;
  height: 129px;
}
.modalWidth .swiper-button-prev{
  margin-left: -105px;
}
.modalWidth .swiper-button-next{
  margin-right: -105px;
}
.modalWidth .swiper-button-prev {
  background: rgba(255, 255, 255, 0.13) url("../assets/images/video/prev.png");
  background-repeat: no-repeat;
  background-size: 18px 32px;
  background-position: center center;
  left: 0;
}
.modalWidth .swiper-button-next {
  background: rgba(255, 255, 255, 0.13) url("../assets/images/video/next.png");
  background-repeat: no-repeat;
  background-size: 18px 32px;
  background-position: center center;
  right: 0;
}
.modalWidth .swiper-button-prev:after,
.modalWidth .swiper-button-next:after {
  content: "";
}*/
.box_flex{
  height: 100%;
}
.el-carousel {
  width: 1138px;
  height: 100%;
  border-radius: 4px;
  border:3px solid #ffffff;
}
.el-carousel__item video {
  width: 100%;
  height: 100%;
  object-fit:cover;
}
.modalWidth >>> .el-carousel__container {
  height: 100%;
}
.leftArrow,.rightArrow{
  width: 82px;
  height: 129px;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 0;
  text-align: center;
  line-height: 129px;
}
.leftArrow{
  margin-right: 24px;
}
.rightArrow{
  margin-left: 24px;
}
.leftArrow img,.rightArrow img{
  vertical-align: middle;
}
.modalWidth >>> .el-carousel__mask {
  background: none;
}
.modalWidth >>> .el-carousel__indicators--outside {
  margin-top: 70px;
}
</style>
